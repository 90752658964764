const utilService = {
  convertToRomanianFormat(date: Date): string {
    const formattedDate = date.toLocaleString('ro-RO', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    return formattedDate;
  },
  convertToDate(dateString: string): Date {
    const hasTime = dateString.includes(',');

    if (hasTime) {
      const [datePart, timePart] = dateString.split(', ');
      const [day, month, year] = datePart.split('.').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);

      return new Date(year, month - 1, day, hours, minutes);
    } else {
      const [day, month, year] = dateString.split('.').map(Number);

      return new Date(year, month - 1, day);
    }
  },
};

export default utilService;
