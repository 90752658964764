import React, { useEffect, useState } from 'react';
import { IEvent } from '../../../common/interfaces/api/tickets.interface';
import MoftButton from '../../../common/components/MoftButton/MoftButton';
import styles from './EventModal.module.scss';
import xLogo from '../../../common/assets/close.png';
import { useKeyPress } from 'react-use';

interface EventModalProps {
  event: IEvent;
  logo: string;
  onHide: () => void;
  button?: boolean;
  buttonText?: string;
  onClick?: () => void;
  show: boolean;
}

const EventModal: React.FC<EventModalProps> = props => {
  const { event, logo, button = true, buttonText, show, onHide, onClick } = props;
  useKeyPress((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      props.onHide();
    }
    return true;
  });
  return show ? (
    <div className={`${styles.customModalOverlay}`}>
      <div className={`${styles.customModal}`}>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-end">
            <button className={`${styles.closeButton}`} onClick={onHide}>
              <img src={xLogo} alt="Close" />
            </button>
          </div>
          <div className="d-flex flex-column m-auto">
            <h2 className={`fw-bolder text-center ${styles.titleTicket}`}>{event.name}</h2>
            <img className={`m-auto pt-2 ${styles.img}`} src={logo} alt="Event" />
          </div>
          <div className={`py-1 h6 ${styles.textContainer}`}>
            <p className={`fw-bolder ${styles.textCategory}`}>Descriere: </p>
            <p dangerouslySetInnerHTML={{ __html: event.description }} />
            {event.ticketType && (
              <>
                <p className={`fw-bolder ${styles.textCategory}`}>Tip bilet: </p>
                <p dangerouslySetInnerHTML={{ __html: event.ticketType }} />
              </>
            )}
            {event.link && (
              <>
                <div className="d-flex justify-content-center">
                  <MoftButton
                    type="button"
                    text={'Detalii'}
                    onClick={() => window.open(event.link)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="m-auto p-0">
            {button && buttonText ? (
              <MoftButton text={buttonText} onClick={onClick} type="button" />
            ) : null}
          </div>
        </div>
        {/* Replace the text "X" with the X logo image */}
      </div>
    </div>
  ) : null;
};

export default EventModal;
