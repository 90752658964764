import Page from '../../common/components/Page/Page';
import LoginForm from './components/LoginForm';
import ilustrationIMG from './components/Background_MOFT.png';
import './components/Login.scss';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

export default function Login() {
  const auth = useAuth();
  const cardClasses1 =
    'col-10 col-sm-8 col-md-6 col-lg-5 col-xl-5 col-xxl-4 card border-0 bg-transparent ';
  return (
    <Page>
      <div className="login-background">
        <div className="row align-items-center justify-content-center offset">
          <div className="text-center">
            <LoginForm />
          </div>
        </div>
      </div>
    </Page>
  );
}
