import { ContactForm } from './ContactForm';
import ContactIMG from './ContactIMG.png';
import './contact.scss';

export default function Contact() {
  return (
    <div className="contact-container d-flex align-items-center justify-content-center ">
      <div className="my-5 text-center">
        <ContactForm />
      </div>
    </div>
  );
}
