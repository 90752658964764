import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import { hasToken } from '../../services/api/auth';
import './Header.scss';

export default function Header(): JSX.Element {
  return (
    <Navbar
      collapseOnSelect
      sticky="top"
      expand="lg"
      style={{ backgroundColor: '#edebe9' }}
      className="navbar-shadow">
      <Container>
        <Navbar.Brand href="/">
          <img src="logo.png" alt="logo" className="navbar-logo"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="right">
          <Nav>
            <Nav.Link data-to-scrollspy-id="about" className="text-black" href="/#about">
              DESPRE
            </Nav.Link>
            <Nav.Link data-to-scrollspy-id="sponsors" className="text-black " href="/#sponsors">
              PARTENERI
            </Nav.Link>
            {/* <Nav.Link data-to-scrollspy-id="podcasts" className="text-black " href="/#podcasts">
              PODCASTURI
            </Nav.Link> */}
            <Nav.Link data-to-scrollspy-id="contact" className="text-black " href="/#contact">
              CONTACT
            </Nav.Link>
            <NavLink className="nav-link text-black" to="/reviews">
              CĂRȚI
            </NavLink>
            <NavLink className="nav-link text-black" to="/recommendations">
              RECOMANDĂRI
            </NavLink>
            {hasToken() && (
              <NavLink className="nav-link text-black" to="/tickets">
                BILETE
              </NavLink>
            )}
            {hasToken() && (
              <NavLink className="nav-link text-black" to="/calendar">
                CALENDAR
              </NavLink>
            )}
            <Nav.Link data-to-scrollspy-id="team" className="text-black " href="/team">
              ECHIPĂ
            </Nav.Link>
            {!hasToken() ? (
              <>
                <NavLink className="nav-link text-black" to="/register">
                  ÎNREGISTRARE
                </NavLink>
                <NavLink className="nav-link text-black" to="/login">
                  AUTENTIFICARE
                </NavLink>
              </>
            ) : (
              <>
                <NavLink className="nav-link text-black" to="/quiz">
                  QUIZ
                </NavLink>
                <NavLink className="nav-link text-black" to="/logout">
                  LOGOUT
                </NavLink>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
