import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_SSO_AUTHORITY,
  client_id: process.env.REACT_APP_SSO_CLIENTID,
  redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
  scope: process.env.REACT_APP_SSO_SCOPES,

  onSigninCallback: user => {
    // this is how you remove the code and state from the url. DO IT!
    window.history.replaceState({}, document.title, window.location.pathname);

    sessionStorage.setItem('token', (user as User)?.access_token);

    // Do other async stuff here

    return Promise.resolve();
  },
};

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
