/* eslint-disable prettier/prettier */
import MoftButton from '../../../../common/components/MoftButton/MoftButton';
import StarryNight from './StarryNight.png';
import Frame from './HomepageFrame.png';
import './About.scss';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function About() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 75);
    }
  }, []);

  const nav = useNavigate();
  return (
    <div className="about-container">
      <h1 className="display-3 textTitlePhone">
        M.O.F.T. I 2024 <br /> DESPRE EVENIMENT{' '}
      </h1>
      <div className="aboutIntroduction">
        <h1 className="display-3 textTitle aboutTitle">
          DESPRE <br /> EVENIMENT
        </h1>
        <img src={StarryNight} alt="Starry Night" className="aboutImg" />
        <div className="aboutButton">
          <MoftButton text={'REZERVĂ BILETE'} onClick={() => nav('/tickets')} type="button" />
        </div>
      </div>
      <div className="aboutTextBox">
        <img src={Frame} alt="Frame" className="frameImg" />
        <p className="textDescription">
          <br />
          Arta și cultura pot reprezenta
          <br />
          un refugiu de tot ce presupune cotidian, așadar LSAC București revine toamna aceasta în
          sprijinul studenților cu cel mai așteptat eveniment cultural, M.O.F.T. (Muzee. Operă.
          Film. Teatru.), ajuns la ediția 26! Semestrul acesta, evenimentul se desfășoară în
          perioada 4 - 24 noiembrie și aduce o mulțime de noutăți, precum workshop-uri în diverse
          domenii care o să vă capteze atenția, dar și multe alte surprize. Dacă ești student al
          Facultății de Automatică și Calculatoare și îți dorești o experiență unică la cele mai
          importante instituții culturale, atunci te invităm să îți creezi un cont:
          <Link className="registerLink" to="/register">
            înregistrează-te
          </Link>
          cu adresa de mail oferită de facultate (@stud.acs.upb.ro).
          <div className="data">
            <br />
          </div>
        </p>
      </div>
      <div>
        <div className="aboutButton mobile-btn">
          <MoftButton text={'REZERVĂ BILETE'} onClick={() => nav('/tickets')} type="button" />
        </div>
        <img src={StarryNight} alt="" className="mobileImg" />
      </div>
    </div>
  );
}
