/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { FormField } from './FormField';
import MoftButton from '../MoftButton/MoftButton';
import './CustomForm.scss';
import { Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

interface IFormData {
  title?: string;
  fields: FormField[];
  onSubmit: Function;
  submitButtonText: string;
  isAuthForm?: boolean;
  isLogin?: boolean;
}

interface IFormError {
  name: string[];
  error: string;
}

export const CustomForm = (data: IFormData): JSX.Element => {
  const auth = useAuth();

  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState<IFormError>({ name: [], error: '' });

  const initialValues: Record<string, string | boolean> = {};
  const validationOptions: Record<string, any> = {};

  data.fields.forEach(({ initialValue, validation, name }) => {
    initialValues[name] = initialValue;
    validationOptions[name] = validation;
  });

  return (
    <>
      <h1 className="title-mobile">{data?.title}</h1>
      <div className="card custom-card">
        <div className="card-body">
          <h1 className="color-primary mb-4 title-desktop">{data?.title}</h1>
          {data.isAuthForm === true ? (
            <>
              <div className="d-flex justify-content-center">
                <MoftButton
                  lsacButton
                  text="Sign in with LSAC"
                  type="button"
                  size="small"
                  onClick={() => void auth.signinRedirect()}
                />
              </div>
              <p className="sso-alt-text">Sau continuă cu</p>
            </>
          ) : null}
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object(validationOptions)}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false);
              setDisabled(true);
              try {
                await data.onSubmit(values);
              } catch (e: any) {
                setErrors(e.response.data);
              }
              setDisabled(false);
            }}>
            <Form className="m-auto w-full form">
              {data.fields.map(
                ({ name, type, placeholder, label, component, CustomStyle }, index) => (
                  <div key={index} className="d-flex flex-column text-start mb-2">
                    <label htmlFor={name} className="fw-bolder h6 color-secondary">
                      {label}
                    </label>

                    {name === 'message' ? (
                      <Field
                        rows={6} // Adjust the number of rows as needed to make it bigger
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        component={component || 'textarea'} // Use 'textarea' for multi-line input
                        className={'mb-1 h6 field p-2 ' + CustomStyle}
                      />
                    ) : (
                      <Field
                        rows={2}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        component={component || 'input'}
                        className={'mb-0 h6 field p-2 ' + CustomStyle}
                      />
                    )}

                    {name !== undefined ? (
                      <ErrorMessage className="text-danger mb-0" component="span" name={name} />
                    ) : null}
                    {errors.name !== undefined
                      ? errors.name.map((e: string) => <span key={index}>{e}</span>)
                      : null}
                  </div>
                ),
              )}
              {/* Afișare eroare din backend */}
              {
                <div>
                  <span>{errors.error}</span>
                </div>
              }
              {disabled ? (
                <Spinner style={{ margin: 'auto' }} animation="border" />
              ) : (
                <div className="d-flex justify-content-center mt-4">
                  <MoftButton type="submit" size="small" text={data.submitButtonText} />
                </div>
              )}
            </Form>
          </Formik>
          {data.isLogin === true ? (
            <NavLink style={{ color: 'black', textDecoration: 'none' }} to="/forgot-password">
              <p className="forgot-password">Am uitat parola</p>
            </NavLink>
          ) : null}
        </div>
      </div>
    </>
  );
};
