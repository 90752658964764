import { useState } from 'react';
import Page from '../../common/components/Page/Page';
import styles from './components/Quiz.module.scss';
import { IAnswer, IQuestion, IQuiz } from '../../common/interfaces/api/quiz.interface';
import { fetchQuiz, submitQuiz } from '../../common/services/api/quiz';
import React from 'react';
import { hasToken } from '../../common/services/api/auth';

export default function Quiz() {
  const [quiz, setQuiz] = useState<IQuiz>();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [chosenAnswer, setChosenAnswer] = useState(0);

  const [submitted, setSubmitted] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const getQuiz = async () => {
    try {
      const res = await fetchQuiz();
      if (res.data.scoreQuiz != undefined) {
        setSubmitted(true);
        setSubmitMessage('Quizul a fost deja completat. Scorul tau este: ' + res.data.scoreQuiz);
        return;
      }

      const questions: IQuestion[] = [];

      (res.data.SelectedQuestion as Array<any>).forEach(question => {
        const answers: IAnswer[] = [];

        (question.question.options as Array<any>).forEach(option => {
          answers.push({
            id: option.id,
            answer: option.text,
          });
        });

        questions.push({
          id: question.question.id,
          question: question.question.text,
          answers: answers,
          selectedAnswer: answers[0],
        });
      });

      setQuiz({
        questions: questions,
      });
    } catch (err: any) {
      setSubmitted(true);
      setSubmitMessage(err.response.data.message);
    }
  };

  useState(() => {
    // Check if the user is logged in
    if (hasToken() == null) {
      window.location.href = '/login';
    }

    getQuiz();
  });

  const nextQuestion = () => {
    const updatedQuiz = quiz;

    updatedQuiz!.questions[currentQuestion].selectedAnswer =
      quiz!.questions[currentQuestion].answers[chosenAnswer - 1];

    setQuiz(updatedQuiz);

    setCurrentQuestion(currentQuestion + 1);
    setChosenAnswer(0);
  };

  const choseAnswer = (index: number) => {
    setChosenAnswer(index);
  };

  const submitResponse = async () => {
    if (quiz != undefined) {
      const res = await submitQuiz(quiz);

      if (res.status == 201) {
        setSubmitted(true);
        setSubmitMessage(res.data);
      } else {
        alert('Eroare la trimiterea raspunsurilor');
      }
    }
  };

  const QuizContent = () => {
    return (
      <>
        {currentQuestion < 3 && (
          <div className={`${styles.question}`}>
            <p className="">{quiz?.questions[currentQuestion].question}</p>
          </div>
        )}

        {currentQuestion < 3 &&
          quiz?.questions[currentQuestion].answers.map((answer, index) => {
            return (
              <React.Fragment key={index}>
                <input
                  type="radio"
                  className="btn-check"
                  name="answer"
                  id={`answer${index}`}
                  onChange={() => choseAnswer(index + 1)}
                  checked={chosenAnswer == index + 1}
                />
                <label className={`${styles.response} btn`} htmlFor={`answer${index}`}>
                  {`${answer.answer}`}{' '}
                </label>
              </React.Fragment>
            );
          })}

        {currentQuestion < quiz!.questions.length ? (
          <div className="d-flex justify-content-center mt-4">
            <button
              className={`${styles.submit} btn`}
              onClick={nextQuestion}
              disabled={chosenAnswer == 0}>
              Urmatoarea intrebare
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-4">
            <button className={`${styles.submit} btn`} onClick={submitResponse}>
              Submit
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <Page>
      <div className={`${styles.quizBackground}`}>
        <div className="container py-4 d-flex flex-column align-items-center">
          <p className={`fw-bolder mb-4 h1 ${styles.sectionTitle}`}>QUIZ</p>

          <div className={`${styles.quizContainer}`}>
            {submitted == false && quiz != undefined && <QuizContent />}

            {submitted == true && <p className={`${styles.submitedMessage}`}>{submitMessage}</p>}
          </div>
        </div>
      </div>
    </Page>
  );
}
