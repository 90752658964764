export default function Footer() {
  // footer menu list
  const footerlist = [
    {
      key: 'facebook',
      link: 'https://www.facebook.com/LsacBucuresti',
      icon: 'bi bi-facebook',
    },
    {
      key: 'instagram',
      link: 'https://www.instagram.com/lsacbucuresti/',
      icon: 'bi bi-instagram',
    },
    {
      key: 'youtube',
      link: 'https://www.youtube.com/user/LSACBucuresti',
      icon: 'bi bi-youtube',
    },
    {
      key: 'twitter',
      link: 'https://twitter.com/lsacbucuresti',
      icon: 'bi bi-twitter',
    },
    {
      key: 'twitch',
      link: 'https://www.twitch.tv/lsac_bucuresti',
      icon: 'bi bi-twitch',
    },
    {
      key: 'web',
      link: 'https://lsacbucuresti.ro/',
      icon: 'bi bi-globe',
    },
  ];

  return (
    <footer
      className="py-3 mt-auto footer"
      style={{ backgroundColor: '#edebe9', borderTop: '1px solid black' }}>
      <div className="d-flex justify-content-center">
        {footerlist.map(item => (
          <a
            className="px-2 px-md-3 text-black"
            href={item.link}
            target="_blank"
            key={item.key}
            rel="noreferrer">
            <i className={item.icon}></i>
          </a>
        ))}
      </div>

      <div className="pt-2">
        <div className="text-black">
          © {new Date().getFullYear()} Copyright:
          <a className="text-decoration-none text-black" href="https://lsacbucuresti.ro/">
            &nbsp;LSAC București
          </a>
        </div>
      </div>
    </footer>
  );
}
