import MoftButton from '../../common/components/MoftButton/MoftButton';
import Page from '../../common/components/Page/Page';
import CarouselComponent from '../../common/components/Carousel/Carousel';
import BookItem from './BookItem';
import { useEffect, useRef, useState } from 'react';
import { ISuggestion } from '../../common/interfaces/api/suggestion.interface';
import { fetchSuggestions, postSuggestion } from '../../common/services/api/books';
import PortraitModal from '../../common/components/PortraitModal/PortraitModal';
import { hasToken } from '../../common/services/api/auth';
import { useNavigate } from 'react-router-dom';

function Books() {
  const [modalOpen, setModalOpen] = useState(false);
  const [books, setBooks] = useState<ISuggestion[]>([]);
  const bookItems = books.map(book => <BookItem key={book.id} book={book} />);
  const [itemsPerSlide, setItemsPerSlide] = useState(window.innerWidth > 767 ? 6 : 2);
  const nav = useNavigate();

  useEffect(() => {
    fetchSuggestions().then(book => setBooks(book));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setItemsPerSlide(window.innerWidth > 767 ? 6 : 2);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSuggestionClickis = () => {
    if (hasToken() != undefined) {
      setModalOpen(true);
    } else {
      nav('/login');
    }
  };

  return (
    <Page>
      <div>
        <h5 className="m-auto mx-3 mt-5 px-sm-0 px-3">
          Cel mai bun lucru pe care îl poți face într-o seară de toamnă este să te relaxezi cu o
          carte bună!📚
          <br />
          De aceea, vă oferim posibilitatea de a sugera cărțile voastre preferate altor studenți la
          fel de pasionați
          <br /> ca voi, dar și de a urmări sugestiile făcute de ceilalți!✨️
        </h5>
      </div>
      <div className="d-flex justify-content-center mt-4 mb-3">
        <MoftButton text="LASĂ O SUGESTIE" type="button" onClick={handleSuggestionClickis} />
      </div>
      <div className="mb-0 ms-2 me-2">
        <p>
          Sugestiile şi review-urile voastre vor fi verificate de către echipă şi adăugate ulterior
          pe site.
        </p>
      </div>
      <div className="d-flex justify-content-center mb-5">
        <CarouselComponent itemsPerSlide={itemsPerSlide} items={bookItems} />
      </div>
      <SuggestionModal show={modalOpen} onHide={() => setModalOpen(false)} />
    </Page>
  );
}

interface SuggestionModalProps {
  show: boolean;
  onHide(): void;
}

function SuggestionModal({ show, onHide }: SuggestionModalProps) {
  const titleRef = useRef<HTMLInputElement>(null);
  const authorRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const nav = useNavigate();

  const submitSuggestion = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    postSuggestion(titleRef.current!.value, authorRef.current!.value, descriptionRef.current!.value)
      // .catch(() => nav('/logout'))
      .then(() => onHide());
    return false;
  };

  return (
    <PortraitModal show={show} onHide={onHide}>
      <div className="modal-top mt-lg-5">
        <h2>LASĂ O SUGESTIE</h2>
      </div>
      <div className=" book-suggestion mt-lg-5 mt-3">
        <form onSubmit={submitSuggestion}>
          <input type="text" placeholder="Titlu carte" ref={titleRef} required />
          <input type="text" placeholder="Autor" ref={authorRef} required />
          <textarea placeholder="Scurta descriere" ref={descriptionRef} required />
          <div className="d-flex justify-content-center mt-3">
            <MoftButton text="TRIMITE" type="submit" size="small" />
          </div>
        </form>
      </div>
    </PortraitModal>
  );
}

export default Books;
