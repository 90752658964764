import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

export default function Logout() {
  const nav = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.isLoading) return;
    void auth
      .removeUser()
      .then(() => {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
      })
      .then(() => nav('/login'));
  }, [auth.isLoading]);

  if (auth.isLoading) {
    return <div>Logging you out...</div>;
  }

  return <></>;
}
