import { forgot } from '../../../common/services/api/auth';
import * as Yup from 'yup';
import { IUserForgot } from '../../../common/interfaces/api/auth.interface';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { REQUIRED } from '../../../common/constants';
import { CustomForm } from '../../../common/components/MoftForm/CustomForm';

export default function ForgotPasswordForm() {
  const nav = useNavigate();

  return (
    <CustomForm
      title="RECUPERARE PAROLĂ"
      fields={[
        {
          name: 'email',
          initialValue: '',
          type: 'email',
          placeholder: 'Email',
          validation: Yup.string()
            .email('Introdu o adresă de email validă')
            .matches(RegExp('.+@stud\\.[a-z]{3,6}\\.upb\\.ro$'), 'Introdu adresa de student')
            .max(255, 'Introdu un email de maxim 255 de caractere')
            .required(REQUIRED),
        },
      ]}
      submitButtonText="Trimite"
      onSubmit={async (values: IUserForgot) => {
        try {
          await forgot(values);
          // success message

          const response = 'Cererea a fost trimisă cu succes pe email';
          await Swal.fire({
            title: response,
            text: `Verifică inbox și spam.\n
            Vei fi redirectat automat pe pagina de login`,
            icon: 'success',
            timer: 5000,
            showConfirmButton: true,
            confirmButtonText: 'Du-mă pe pagina de login',
          }).then(() => {
            nav('/login');
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          // Error message
          const response = e.response.data.error;
          await Swal.fire({
            title: response,
            icon: 'error',
            timer: 5000,
            showConfirmButton: false,
          });

          throw e;
        }
      }}
    />
  );
}
