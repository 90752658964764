import React from 'react';
import './Team.scss';
import Page from '../../common/components/Page/Page';
import { Card } from 'react-bootstrap';

const teamData = [
  { name: 'Tudor Ștefania', position: 'Manager Proiect', image: '/org_team/mp.jpg' },
  { name: 'Ilie Dana', position: 'Vice Responsabil', image: '/org_team/vice.jpg' },
  { name: 'Şendrescu Vlad-Mihai', position: 'Manager Design', image: '/org_team/md_design.jpg' },
  { name: 'Frățilă Aida-Florina', position: 'Manager FR', image: '/org_team/md_fr.jpg' },
  { name: 'Pleşu Andreea Elena', position: 'Manager HR', image: '/org_team/md_hr.jpg' },
  { name: 'Onțică Alexandra-Elena', position: 'Manager IT', image: '/org_team/md_it.jpg' },
  { name: 'Grozoiu Ştefania', position: 'Manager Logistică', image: '/org_team/md_logistica.jpg' },
  {
    name: 'Hanuș Anemona',
    position: 'Manager Multimedia',
    image: '/org_team/md_mm.jpg',
  },
  { name: 'Almăjanu Ana Gabriela', position: 'Manager PR', image: '/org_team/md_pr.jpg' },
  { name: 'Vîlcea Ştefania', position: 'Mentor', image: '/org_team/mentor3.jpg' },
  { name: 'Povarnă Andreea', position: 'Mentor', image: '/org_team/mentor1.jpg' },
  { name: 'Popescu Alexandra', position: 'Mentor', image: '/org_team/mentor2.jpg' },
  { name: 'Florean Sara-Denisa', position: 'Mentor', image: '/org_team/mentor4.jpg' },
];

export default function Team() {
  return (
    <Page>
      <div className="containerAll">
        <p className="titleEchipa">ECHIPA DE ORGANIZARE</p>

        <div className="imageRow">
          {teamData.map((member, index) => (
            <Card key={index} className="containerImage">
              <img className="teamImages" src={member.image} alt={member.name} loading="lazy" />
              <p className="name">{member.name}</p>
              <p className="positionInTeam">{member.position}</p>
            </Card>
          ))}
        </div>
      </div>
    </Page>
  );
}
