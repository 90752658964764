import Page from '../../common/components/Page/Page';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import './components/ForgotPassword.scss';

export default function ForgotPassword() {
  const cardClasses = 'col-8 col-sm-10 col-md-6 col-lg-5 col-xl-5 col-xxl-4';
  return (
    <Page>
      <div className="forgot-password-background">
        <div className="row align-items-center justify-content-center offset">
          <div className="text-center my-5 px-5">
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    </Page>
  );
}
