import styles from '../PodcastList/PodcastModal.module.scss';
import PlaceholderImage from '../../../../common/assets/podcast-placeholder.png';
import { ISponsor } from '../../../../common/interfaces/api/sponsors.interface';
import PortraitModal from '../../../../common/components/PortraitModal/PortraitModal';

interface IProps {
  sponsor: ISponsor;
  onHide(): void;
  onClick?(): void;
  show: boolean;
  buttonText: string;
}

export function PartnerModal(props: IProps) {
  const { sponsor, show, onHide } = props;
  return (
    <>
      <PortraitModal show={show} onHide={onHide}>
        <div className="modal-top mt-5">
          <h2>{sponsor.name}</h2>
          <div className={`mt-4 ${styles.imageContainer}`}>
            {sponsor.logoUrl ? (
              <img className={`m-auto ${styles.img}`} src={sponsor.logoUrl} alt="Partner image" />
            ) : (
              <img className={`m-auto ${styles.img}`} src={PlaceholderImage} alt="Partner image" />
            )}
          </div>
        </div>
        <div className="book-reviews mt-3">
          <p className="p-2">{sponsor.description}</p>
        </div>
      </PortraitModal>
    </>
  );
}
