import Header from './Header';
import Footer from './Footer';

interface IPageProps {
  children: JSX.Element | JSX.Element[];
}

export default function Page({ children }: IPageProps) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex-grow-1">{children}</div>
      <Footer />
    </div>
  );
}
