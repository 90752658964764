import { useEffect, useState } from 'react';
import { getRecommendations } from '../../../common/services/api/tickets';
import { IEvent } from '../../../common/interfaces/api/tickets.interface';
import EventCard from '../../Tickets/components/EventCard';
import './RecommendationsComponent.scss';

interface IRecommendationsComponentProps {
  recommendations: IEvent[];
}

export default function RecommendationsComponent({
  recommendations,
}: IRecommendationsComponentProps) {
  return (
    <div className="recommendations-component-container">
      <h1 className="recommendations-component-title">M.O.F.T. All Year Long</h1>
      <div>
        <h4 className="info-text">
          Evenimentele <b className="red">recomandate</b> mai jos sunt printre cele mai accesibile
          bilete la spectacole culturale destinate studenților ACS!
        </h4>
        <h5 className="info-text">Acestea o să fie actualizate periodic!</h5>
      </div>
      <div className="recommendations-component-event-cards">
        {recommendations.map((event: any, index) => {
          return <EventCard event={{ ...event }} key={index} is_recommedation={true} />;
        })}
      </div>
    </div>
  );
}
