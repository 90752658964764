import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { hasToken } from '../../../common/services/api/auth';
import { createMyTicket, deleteMyTicket } from '../../../common/services/api/tickets';
import MoftButton from '../../../common/components/MoftButton/MoftButton';
import { CatetgoryUIEnum, IEventCard } from '../../../common/interfaces/api/tickets.interface';
import Swal from 'sweetalert2';
import styles from './EventCard.module.scss';
import EventModal from './EventModal';
import { endEventDate } from '../../../common/constants';
import utilsService from '../../../common/services/utils.service';

export default function EventCard({ event, ticket, extraChecks, is_recommedation }: IEventCard) {
  const logo = event.imgUrl;

  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const DeleteTicket = () => {
    if (ticket === undefined) return;

    if (!hasToken()) nav('/login');
    setIsLoading(true);
    try {
      Swal.fire({
        title: 'Ești sigur că renunți la bilet?',
        text: 'După e posibil să nu mai fie bilete disponibile',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#28a745',
        confirmButtonText: 'Renunț',
        cancelButtonText: 'Îl păstrez',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await deleteMyTicket(ticket.id);
            setIsSubmitted(true);
            Swal.fire('Cerere înregistrată cu succes', 'Ai renunțat cu succes la bilet', 'success');
          } catch (e: any) {
            Swal.fire({
              title: 'Eroare',
              text: 'Evenimentul s-a terminat!',
              icon: 'error',
              confirmButtonColor: '#28a745',
            });
          }
        }
      });
    } catch (e) {
      nav('/logout');
    }
    setIsLoading(false);
    setShow(false);
  };

  const CreateTicket = async () => {
    if (!hasToken()) nav('/login');
    setIsLoading(true);
    try {
      await createMyTicket(event.id);
      setIsSubmitted(true);
    } catch (e: any) {
      Swal.fire({
        title: 'Eroare',
        text: e.response.data.message,
        icon: 'error',
        confirmButtonColor: '#28a745',
      });
    }
    setIsLoading(false);
    setShow(false);
  };

  function TicketBtn() {
    if (isSubmitted)
      return (
        <p style={{ color: 'green', marginTop: '24px' }}>
          <strong>Cerere inregistrata!</strong>
        </p>
      );

    if (ticket === undefined) return <></>;

    return (
      <>
        {ticket.status === 'BOOKED' ? (
          <>
            <p className="h6 mb-2 text-success">
              <strong>Biletul a fost rezervat cu succes!</strong>
            </p>{' '}
          </>
        ) : (
          <p className="h6 mb-2 text-warning">
            <strong>Esti pe lista de asteptare!</strong>
          </p>
        )}
        {isLoading ? (
          <Spinner style={{ margin: 'auto', marginTop: '16px' }} animation="border" />
        ) : (
          <div className="d-flex justify-content-center">
            <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
            <EventModal
              event={event}
              logo={logo}
              buttonText={'Renunță bilet'}
              onHide={handleClose}
              onClick={DeleteTicket}
              show={show}
            />
          </div>
        )}
      </>
    );
  }

  function CategoryBtn() {
    if (!is_recommedation && event.date) {
      const parts = event.date.split(', ');
      const dateParts = parts[0].split('.');
      const year = parseInt(dateParts[2], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JavaScript Date object
      const day = parseInt(dateParts[0], 10);

      let givenDate;
      if (parts[1]) {
        const time = parts[1].split(':');
        const hour = parseInt(time[0], 10);
        const minute = parseInt(time[1], 10);
        givenDate = new Date(year, month, day, hour, minute);
      } else {
        givenDate = new Date(year, month, day);
      }

      // Create a Date object for the given date
      // Get today's date
      const today = new Date();

      // Compare the dates
      if (givenDate && givenDate < today) {
        return (
          <>
            <p className={`${styles.event_card_text}`}>
              <strong>Evenimentul s-a încheiat.</strong>
            </p>
            <div className="d-flex justify-content-center">
              <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
            </div>
            <EventModal event={event} logo={logo} button={false} onHide={handleClose} show={show} />
          </>
        );
      }
    }

    if (is_recommedation) {
      return (
        <>
          <p className={`${styles.event_card_text}`}>
            <strong>
              Eveniment disponibil sub forma de
              <br />
              recomandare
            </strong>
          </p>
          <div className="d-flex justify-content-center">
            <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
          </div>
          <EventModal event={event} logo={logo} button={false} onHide={handleClose} show={show} />
        </>
      );
    }

    if (event.id === 0 || !event.isReleased) {
      return (
        <>
          <p className={`${styles.event_card_text}`}>
            <strong>Evenimentul nu este deschis deocamdată.</strong>
          </p>
          <div className="d-flex justify-content-center">
            <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
          </div>
          <EventModal event={event} logo={logo} button={false} onHide={handleClose} show={show} />
        </>
      );
    }

    if (event.tickets.length !== 0)
      return (
        <>
          <p className="h6 m-2 text-success">
            <strong>Verifica sectiune de biletele mele!</strong>
          </p>
          <div className="d-flex justify-content-center">
            <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
          </div>
          <EventModal event={event} logo={logo} button={false} onHide={handleClose} show={show} />
        </>
      );

    if (extraChecks) {
      const categoryKey = `${event.category}${event.release}`;
      if (extraChecks.ticketsPerCategory[categoryKey] >= 2) {
        return (
          <>
            <p className="h6 m-2 text-danger">
              <strong>
                Aţi luat deja 2 bilete în tura {event.release} la categoria{' '}
                {CatetgoryUIEnum[event.category]}!
              </strong>
            </p>
            <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
            <EventModal event={event} logo={logo} button={false} onHide={handleClose} show={show} />
          </>
        );
      }

      const eventDate = event.date.slice(0, 10);
      if (extraChecks.occupiedDates[eventDate]) {
        return (
          <>
            <p className="h6 m-2 text-danger">
              <strong>
                Aveţi bilet in aceeasi zi la {extraChecks.occupiedDates[eventDate].name}
              </strong>
            </p>
            <div className="d-flex justify-content-center">
              <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
            </div>
            <EventModal event={event} logo={logo} button={false} onHide={handleClose} show={show} />
          </>
        );
      }
    }

    if (isSubmitted)
      return (
        <>
          <p className="h6 m-2 text-success">
            <strong>Cerere inregistrata!</strong>
          </p>
          <div className="d-flex justify-content-center">
            <MoftButton text="Detalii" onClick={handleShow} type={'button'} size="small" />
          </div>
          <EventModal event={event} logo={logo} button={false} onHide={handleClose} show={show} />
        </>
      );

    let ticketCounter = null;
    if (event.availableTickets === 0)
      ticketCounter = (
        <p className={`${styles.event_card_text}`}>
          <strong>Bilete indisponibile!</strong>
        </p>
      );
    else
      ticketCounter = (
        <p className={`${styles.event_card_text}`}>
          {event.is_limited === false
            ? 'Bilete Nelimitate!'
            : event.availableTickets === 1
              ? 'Un bilet rămas'
              : event.availableTickets + ' Bilete rămase'}
        </p>
      );

    if (isLoading)
      return (
        <>
          {ticketCounter}
          <Spinner style={{ margin: 'auto', marginTop: '16px' }} animation="border" />
        </>
      );

    return (
      <div>
        {ticketCounter}
        <div className="d-flex justify-content-center">
          <div style={{ maxWidth: '180px' }}>
            <MoftButton
              text={event.availableTickets !== 0 ? 'Rezervă' : 'Intră pe lista de așteptare'}
              onClick={handleShow}
              type={'button'}
              size="small"
            />
            <EventModal
              event={event}
              logo={logo}
              buttonText={
                event.availableTickets !== 0
                  ? 'Confirmare rezervare'
                  : 'Intră pe lista de așteptare'
              }
              onHide={handleClose}
              onClick={CreateTicket}
              show={show}
            />
          </div>
        </div>
      </div>
    );
  }

  function SmartBtn() {
    if (ticket !== undefined && event.date) return <TicketBtn />;
    else return <CategoryBtn />;
  }

  function eventDate(date: string) {
    switch (date) {
      case 'anytime':
        return 'Oricând';

      case 'period':
        return 'Pe parcursul evenimentului';

      default: {
        return utilsService.convertToRomanianFormat(utilsService.convertToDate(date));
      }
    }
  }

  return (
    <div className={`${styles.tickets_container}`}>
      <div className={`${styles.event_card}`}>
        <div className={`${styles.event_card_content}`}>
          <img className={`${styles.event_img}`} src={logo} alt="Event" />
          <div>
            <p className={`${styles.event_card_text}`}>
              <strong>{event.name}</strong>
            </p>
            {/* TODO nu e bun 23.05.2030 */}
            {event.ticketType && event.ticketType !== 'Recommendation' && (
              <p className={`${styles.event_card_text}`}>{event.ticketType}</p>
            )}
            {!is_recommedation && (
              <p className={`${styles.event_card_text}`}>{eventDate(event.date)}</p>
            )}
            {event.release && <p className={`${styles.event_card_text}`}>Tura {event.release}</p>}
            <div>
              <SmartBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
