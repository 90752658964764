import { ReactNode } from 'react';
import { Fragment } from 'react';

interface CarouselSlideProps<Item extends ReactNode> {
  items: Item[];
  itemsPerSlide: number;
  startIndex: number;
}

function CarouselSlideComponent<Item extends ReactNode>({
  items,
  itemsPerSlide,
  startIndex,
}: CarouselSlideProps<Item>) {
  const slideItems = items.slice(startIndex, startIndex + itemsPerSlide);

  return (
    <div className="item-container row justify-content-start">
      {slideItems.map((Item, i) => (
        <Fragment key={startIndex + i}>{Item}</Fragment>
      ))}
    </div>
  );
}

export default CarouselSlideComponent;
