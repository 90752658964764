import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import './App.css';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Verify from './pages/Verify/Verify';
import Tickets from './pages/Tickets/Tickets';
import Recommendations from './pages/Recommendations/Recommendations';
import Page404 from './pages/Page404/Page404';
import Quiz from './pages/Quiz/Quiz';
import Calendar from './pages/Calendar/Calendar';
import Placeholder from './pages/Placeholder/Placeholder';
import Team from './pages/Team/Team';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import Books from './pages/Books/Books';

function App() {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isLoading) return;
    if (!auth.isAuthenticated) return;

    //check if user is student
    const user = auth.user!.profile;

    if (!user.student) {
      // show modal
      alert('You are not a student'); // TODO: use the same modal u have for normal login
      sessionStorage.removeItem('token');
      auth.removeUser();
    } else {
      const student_email = user.student_email as string;
      if (
        !user.student_email ||
        !(student_email.endsWith('upb.ro') || student_email.endsWith('pub.ro'))
      ) {
        // show modal
        alert('You are not a UPB student');
        sessionStorage.removeItem('token');
        auth.removeUser();
      } else if (!user.student_email_confirmed) {
        // show modal
        alert(
          "Verify your student email.\n If you didn't receive a confirmation email, check out this page:\n https://sso.lsacbucuresti.ro/Identity/Account/Manage/Student \n (Student Status > Resend verification email)",
        );
        sessionStorage.removeItem('token');
        auth.removeUser();
      }
    }
  }, [auth.isLoading]);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/checkAccount/:token" element={<Verify />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/recommendations" element={<Recommendations />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/*" element={<Page404 />} />
          <Route path="/team" element={<Team />} />
          <Route path="/reviews" element={<Books />} />
          <Route path="/404" element={<Page404 />} />
        </Routes>
      </BrowserRouter>

      {/* to uncomment the above lines when the event starts */}

      {/* <Placeholder /> */}
    </div>
  );
}

export default App;
