import { Modal } from 'react-bootstrap';
import './PortraitModal.scss';

interface PortraitModalProps {
  show: boolean;
  onHide?(): void;
  children: React.ReactNode | React.ReactNode[];
}

function PortraitModal(props: PortraitModalProps) {
  return (
    <Modal show={props.show} onHide={props.onHide} centered className="portrait-modal">
      <Modal.Header>
        <button type="button" className="btn-close" aria-label="Close" onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}

export default PortraitModal;
