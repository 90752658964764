import Carousel from 'react-bootstrap/Carousel';
import CarouselSlideComponent from './CarouselSlide';
import './Carousel.scss';
import { ReactNode } from 'react';

interface CarouselProps<Item extends ReactNode> {
  items: Item[];
  itemsPerSlide: number;
}

function CarouselComponent<Item extends ReactNode>({ items, itemsPerSlide }: CarouselProps<Item>) {
  const carouselSlides: ReactNode[] = [];

  for (let i = 0; i < items.length; i += itemsPerSlide) {
    carouselSlides.push(
      <CarouselSlideComponent key={i} items={items} itemsPerSlide={itemsPerSlide} startIndex={i} />,
    );
  }

  return (
    <Carousel className="carousel-container" variant="dark" indicators={false} interval={3000}>
      {carouselSlides.map((Slide, index) => (
        <Carousel.Item key={index}>{Slide}</Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselComponent;
