import Page from '../../common/components/Page/Page';
import RegisterForm from './components/RegisterForm';
import './components/Register.scss';

export default function Register() {
  const cardClasses1 =
    'col-10 mb-sm-5 col-sm-9 col-md-6 col-lg-5 col-xl-5 col-xxl-4 card border-0 bg-transparent';
  return (
    <Page>
      <div className="register-background">
        <div className="row align-items-center justify-content-center offset">
          <div className="text-center">
            <RegisterForm />
          </div>
        </div>
      </div>
    </Page>
  );
}
