import { CustomForm } from '../../../common/components/MoftForm/CustomForm';
import { register } from '../../../common/services/api/auth';
import { IUserRegister } from '../../../common/interfaces/api/auth.interface';
import { REQUIRED } from '../../../common/constants';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function RegisterForm() {
  const nav = useNavigate();
  const defaultErrorMessage = 'Nu te-ai putut înregistra. Te rugăm încearcă mai târziu';
  type ErrorMessages = {
    [key: string]: string;
  };

  const errorMessages: ErrorMessages = {
    'Email is already used.': 'Deja te-ai înregistrat cu acest email!',
    // Add more error messages as needed
  };
  return (
    <CustomForm
      // title="INREGISTRARE"
      isAuthForm
      fields={[
        {
          name: 'name',
          initialValue: '',
          type: 'text',
          placeholder: 'Nume Complet',
          validation: Yup.string()
            .min(5, 'Numele trebuie sa aiba minim 5 caractere')
            .max(255, 'Introdu un nume de maxim 255 de caractere')
            .required(REQUIRED),
        },
        {
          name: 'email',
          initialValue: '',
          type: 'email',
          placeholder: 'Email',
          validation: Yup.string()
            .email('Introdu o adresă de email validă')
            .matches(RegExp('.+@stud\\.acs\\.upb\\.ro$'), 'Introdu adresa de student')
            .max(255, 'Introdu un email de maxim 255 de caractere')
            .required(REQUIRED),
        },
        {
          name: 'phone',
          initialValue: '',
          type: 'text',
          placeholder: 'Telefon (+407########)',
          validation: Yup.string()
            // TODO regex for phone number with +40 7########
            // .matches(RegExp('^(07)[0-9]{8}$'), 'Introdu un număr de telefon valid') <- GRESIT
            // regex for phone number with +40 7########
            .matches(RegExp('^[+](407)[0-9]{8}$'), 'Introdu un număr de telefon valid')
            .required(REQUIRED),
        },
        {
          name: 'password',
          initialValue: '',
          type: 'password',
          placeholder: 'Parola',
          validation: Yup.string()
            .min(8, 'Introdu o parolă de minim 8 caractere')
            .max(255, 'Introdu o parolă de maxim 8 caractere')
            .required(REQUIRED),
        },
        {
          name: 'password_confirmation',
          initialValue: '',
          type: 'password',
          placeholder: 'Confirmare parola',
          validation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Parolele nu se potrivesc')
            .required(REQUIRED),
        },
      ]}
      submitButtonText="Inregistrare"
      onSubmit={async (values: IUserRegister) => {
        try {
          await register(values);

          // success message
          const response =
            'Te-ai înregistrat cu succes\n Link-ul pentru confirmare va veni pe email\n Verifică și spam';

          Swal.fire({
            title: response,
            icon: 'success',
            showConfirmButton: true,
          }).then(() => nav('/login'));
        } catch (e: any) {
          // Error message
          let response = defaultErrorMessage;
          console.log(e);
          if (e.response.data.message && errorMessages[e.response.data.message]) {
            response = errorMessages[e.response.data.message];
          }

          Swal.fire({
            title: response,
            icon: 'error',
            timer: 5000,
            showConfirmButton: false,
          });

          throw e;
        }
      }}
    />
  );
}
